<template>
  <CRow>
    <CCol col="12" lg="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <strong>{{ $lang.company.crud.create }}</strong>
        </CCardHeader>
        <CForm novalidate @submit.prevent="onSubmit">
          <CCardBody>
            <CRow>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.company.form.company_name }}
                    <required_span
                    />
                  </label>
                  <input
                      :maxlength="maxlength.company_name"
                      type="text"
                      :placeholder="$lang.company.form.company_name"
                      class="form-control"
                      v-model="company.company_name"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.company.company_name.$error &&
                      !$v.company.company_name.required
                    "
                  >{{ $lang.company.validation.required.company_name }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.company.form.email }}
                    <required_span
                    />
                  </label>
                  <input
                      type="text"
                      :maxlength="maxlength.company_name"
                      :placeholder="$lang.company.form.email"
                      class="form-control"
                      v-model="company.email"
                  />
                  <small
                      class="error"
                      v-if="$v.company.email.$error && !$v.company.email.required"
                  >{{ $lang.company.validation.required.email }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.company.form.address }}
                    <required_span
                    />
                  </label>
                  <input
                      type="text"
                      :maxlength="maxlength.address"
                      :placeholder="$lang.company.form.address"
                      class="form-control"
                      v-model="company.address"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.company.address.$error && !$v.company.address.required
                    "
                  >{{ $lang.company.validation.required.address }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.company.form.contact_no }}
                    <required_span
                    />
                  </label>
                  <vue-tel-input
                      :maxlength="maxlength.mobile"
                      v-model="company.mobile"
                      v-on:country-changed="countryChanged"
                      v-bind="bindProps"
                  ></vue-tel-input>
                  <small
                      class="error"
                      v-if="
                      $v.company.mobile.$error && !$v.company.mobile.required
                    "
                  >{{ $lang.company.validation.required.contact_no }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.company.form.cp_name }}
                    <required_span
                    />
                  </label>
                  <input
                      type="text"
                      :maxlength="maxlength.cp_name"
                      :placeholder="$lang.company.form.cp_name"
                      class="form-control"
                      v-model="company.cp_name"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.company.cp_name.$error && !$v.company.cp_name.required
                    "
                  >{{ $lang.company.validation.required.cp_name }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.company.form.cp_contact_no }}
                    <required_span
                    />
                  </label>
                  <vue-tel-input
                      :maxlength="maxlength.cpMobile"
                      v-model="company.cpMobile"
                      v-on:country-changed="cpCountryChanged"
                      v-bind="bindProps"
                  ></vue-tel-input>
                  <small
                      class="error"
                      v-if="
                      $v.company.cpMobile.$error &&
                      !$v.company.cpMobile.required
                    "
                  >{{
                      $lang.company.validation.required.cp_contact_no
                    }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.company.form.cp_email }}
                    <required_span
                    />
                  </label>
                  <input
                      type="text"
                      :maxlength="maxlength.cp_email"
                      :placeholder="$lang.company.form.cp_email"
                      class="form-control"
                      v-model="company.cp_email"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.company.cp_email.$error &&
                      !$v.company.cp_email.required
                    "
                  >{{ $lang.company.validation.required.cp_email }}</small
                  >
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="submitted"
            >
              <CIcon name="cil-check-circle"/>
              {{ $lang.buttons.general.crud.submit }}
            </CButton>
            <CButton color="danger" size="sm" @click="cancel">
              <CIcon name="cil-ban"/>
              {{ $lang.buttons.general.crud.cancel }}
            </CButton>
          </CCardFooter>
        </CForm>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {company} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueToasted from "vue-toasted";
import moment from "moment";
import VueTelInput from "vue-tel-input-fix";
import "vue-tel-input-fix/dist/vue-tel-input.css";
import {Maxlength} from "../../store/maxlength";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
Vue.use(VueTelInput);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "CompanyCreate",
  mixins: [Mixin],
  components: {
    required_span
  },
  data() {
    return {
      bindProps: {
        mode: "international",
        placeholder: "Contact No.",
        defaultCountry: "IN",
        dropdownOptions: {
          showFlags: true,
        },
        inputOptions: {
          maxlength: Maxlength.company.mobile,
        },
        validCharactersOnly: true,
      },
      submitted: false,
      err_msg: "",
      dismissCountDown: 0,
      self: this,
      module: company,
      userOptions: [],
      company: {
        company_name: "",
        email: "",
        address: "",
        cp_email: "",
        cpCountryCode: "",
        cpMobile: "",
        cp_name: "",
        start_date: "",
        end_date: "",
        countryCode: "",
        mobile: "",
      },
      maxlength: {
        company_name: Maxlength.company.company_name,
        email: Maxlength.company.email,
        address: Maxlength.company.address,
        cp_email: Maxlength.company.cp_email,
        cp_name: Maxlength.company.cp_name,
      },
    };
  },
  validations: {
    company: {
      company_name: {
        required,
      },
      mobile: {
        required,
      },
      cpMobile: {
        required,
      },
      cp_email: {
        required,
      },
      cp_name: {
        required,
      },
      email: {
        required,
      },
      address: {
        required,
      },
    },
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
  },
  methods: {
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    sendAlert() {
      this.$root.$emit("alert", "test1", "test2");
    },
    countryChanged(country) {
      let self = this;
      self.company.countryCode = "+" + country.dialCode;
      let mobile = self.company.mobile;
      self.company.mobile = mobile.replace("+" + country.dialCode, "").trim();
    },
    cpCountryChanged(country) {
      let self = this;
      self.company.cpCountryCode = "+" + country.dialCode;
      let mobile = self.company.cpMobile;
      self.company.cpMobile = mobile.replace("+" + country.dialCode, "").trim();
    },
    onSubmit() {
      let self = this;
      this.$v.company.$touch();
      if (this.$v.company.$invalid) {
        self.submitted = false;
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true;
        this.submitStatus = "PENDING";
        setTimeout(() => {
          let self = this;
          let mobile = self.company.mobile;
          let mobileP = mobile.replace(self.company.countryCode, "").trim();
          let cpmobile = self.company.cpMobile;
          let cpMobile = cpmobile
              .replace(self.company.cpCountryCode, "")
              .trim();
          const formData = new FormData();
          formData.append("companyName", self.company.company_name);
          formData.append("email", self.company.email);
          formData.append("address", self.company.address);
          formData.append("cpEmail", self.company.cp_email);
          formData.append("contactNoCode", self.company.countryCode);
          formData.append("contactNo", mobileP);
          formData.append("cpName", self.company.cp_name);
          formData.append("cpContactNoCode", self.company.cpCountryCode);
          formData.append("cpContactNo", cpMobile);
          formData.append("endDate", self.company.end_date);
          formData.append("startDate", self.company.start_date);
          axios
              .post(this.createUrlWeb(this.module), formData)
              .then(function (response) {
                self.submitted = false;
                if (response.data.code === 200) {
                  localStorage.setItem("showAlert", "This is my alert");
                  self.sendAlert();
                  self.$router.push({path: "/company"});
                } else {
                  self.err_msg = response.data.response.message;
                  self.dismissCountDown = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.err_msg = data;
            self.dismissCountDown = 10;
            self.submitted = false; //Enable Button
          });
        }, 500);
      }
    },
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/company"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
